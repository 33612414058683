import React, { useState } from "react";
import Add from "@material-ui/icons/Add";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { Box, Grid, makeStyles } from "@material-ui/core";
import OptionsGroup from "../../components/OptionsGroup";
import Button from "../../components/Button";
import { debtOptions } from "../../../../commons/loan-application-constants";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "24px",
    [theme.breakpoints.down("xs")]: {
      padding: "0",
    },
  },
}));
const DebtsSelection = ({ selectedDebts, setSelectedDebts }) => {
  const classes = useStyles();
  const shortListedDebts = debtOptions.slice(0, 9);
  const [displayedDebts, setDisplayedDebts] = useState(shortListedDebts);

  const toggleDebtList = () => {
    if (displayedDebts.length === shortListedDebts.length) {
      setDisplayedDebts(debtOptions);
      return;
    }
    setDisplayedDebts(shortListedDebts);
  };

  return (
    <Box classes={{ root: classes.container }}>
      <Grid container spacing={6} xs={12} sm={6}>
        <OptionsGroup
          title="Select what facilities you have"
          options={displayedDebts}
          selectedValue={selectedDebts}
          onSelect={setSelectedDebts}
          multiple
          // allowUnSelect={false}
        />
        <Grid item>
          <Button
            endIcon={
              displayedDebts.length === shortListedDebts.length ? (
                <Add />
              ) : (
                <ExpandLessIcon />
              )
            }
            onClick={toggleDebtList}
          >
            {displayedDebts.length === shortListedDebts.length
              ? `See full list`
              : `Show less`}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DebtsSelection;
